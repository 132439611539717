<template>
  <div class="home">
    <Head recruit="true"></Head>
    <div class="main">
      <div class="centerHead" v-if="userInfo">
        <div class="content">
          <div class="logo" v-if="userInfo.loginDO">
            <img :src="userInfo.avatar" alt="">
            {{userInfo.loginDO.account}}
          </div>
          <div class="data">
            <div class="item" @click="$router.push('/recruitCenter/releaseed')">
              {{showCount.releaseCount}}
              <p>发布</p>
            </div>
            <div class="item" @click="$router.push('/recruitCenter/collection')">
              {{showCount.collectionCount}}
              <p>收藏</p>
            </div>
            <!-- <div class="item" @click="$router.push('/recruitCenter/wallet')">
              88.00
              <p>余额</p>
              <div class="btn">明细</div>
            </div> -->
          </div>
          
        </div>
      </div>
      <div class="content center" style="min-height: 500px;padding: 15px 0;">
        <Resume style="margin-right: -20px" :dataList="collectionList"/>
        <el-empty style="padding: 60px 0 300px" v-if="!total" :image-size="100"></el-empty>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import event from '@/utils/event';
import {count} from '@/api/zp'
import Head from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Resume from '@/components/Resume'
import { resumeList } from '@/api/zp'
export default {
  name: 'Center',
  data() {
    return {
      search: {
        pageNum: 1,
        pageSize: 10
      },
      total:0,
      collectionList: [],
      userInfo: {},
      showCount: {},
    };
  },
  created(){
    this.loadCount()
    this.getcollection()
  },
  mounted(){
    try {
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    } catch (error) {
      this.userInfo = {}
    }
    event.$on('loadCount',this.loadCount)
  },
  components: {
    Head,
    Footer,
    Resume
  },
  methods: {
   loadCount(){
    count().then(res => {
      this.showCount = res.data
    })
   },
   // 获取收藏
  getcollection() {
    resumeList({...this.search, id: this.$route.query.id}).then(res => {
      this.collectionList = res.rows
      this.total = res.total
    })
  }
  },
};
</script>
<style scoped lang="scss">
.centerHead{
  background: #EDF5FF;
  padding: 32px 0;
  overflow: hidden;
  .logo{
    float: left;
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    line-height: 118px;
    img{
      width: 118px;
      height: 118px;
      vertical-align: middle;
      margin-right: 18px;
      border-radius: 5px;
    }
  }
  .data{
    float: right;
    .item{
      margin-top: 22px;
      width: 117px;
      float: left;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
      &:nth-child(2){
        border-left: 1px solid #C3DAFC;
        // border-right: 1px solid #C3DAFC;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-top: 6px;
      }
      .btn{
        display: inline-block;
        border-radius: 13px 13px 13px 13px;
        opacity: 1;
        border: 1px solid #0780E7;
        font-size: 14px;
        font-weight: 400;
        color: #0780E6;
        margin-top: 10px;
        width: 60px;
      }
    }
  }
}
.center{
  overflow: hidden;
  .left{
    float: left;
    width: 200px;
    margin: 20px 0;
    li{
      border-bottom: 1px solid #EEEEEE;
      &:last-child{
        border: 0;
      }
      a{
        display: block;
        line-height: 98px;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        background: #fff;
        color: #333333;
        &.atthis{
          color: #0780E6;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 6px;
            height: 98px;
            background: linear-gradient(180deg, rgba(124, 195, 255, 0) 0%, #3580DF 100%);
          }
        }
      }
    }
  }
  .right{
    margin-left: 220px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    overflow: hidden;
  }
}
.clear{
  clear: both;
}
</style>
